// constants
const DIR_LEFT = 0;
const DIR_UP = 1;
const DIR_RIGHT = 2;
const DIR_DOWN = 3;

const FPS_START = 5.0;

const squareSize = 25;
const gridsize_x = 15;
const gridsize_y = 12;
const foodsize = 4;
const width = squareSize * gridsize_x;
const height = squareSize * gridsize_y;

export var interval = null;
let mainCanvas = null;

const backgroundStyle = "#B8E0A4";
const snakeStyle = "#4C4C4C";
const gridStyle = "#B8E0A4";
const borderStyle = "#BBBBBB";
const fruitStyle = "#4C4C4C";

// game state
let state = {};

function onStart() {
  resetState();

  // reschedule new game loop
  if (interval) {
    clearInterval(interval);
    interval = undefined;
  }
  interval = setInterval(mainLoop, 1000.0 / FPS_START);
}

export function snakeMain() {
  mainCanvas = document.getElementById("mainCanvas");
  window.addEventListener("keydown", onKeyDown);

  onStart();
}

function resetState() {
  clearCanvas(mainCanvas);

  state = {
    dead: false,
    eaten: 0,
    dir: DIR_RIGHT,
    last_dir: null,
    queue: [
      { x: 0, y: 0 },
      { x: 1, y: 0 },
      { x: 2, y: 0 },
      { x: 3, y: 0 },
    ],
    food_map: [],
    food_coord: null,
    fps: FPS_START,
    speed_up: false,
  };
  // for (var attr in newstate) {
  //   state[attr] = newstate[attr];
  // }

  // generate array of all locations
  for (var xc = 0; xc < gridsize_x; xc++) {
    for (var yc = 0; yc < gridsize_y; yc++) {
      state.food_map.push(xc + "," + yc);
    }
  }

  dropRandomFruit();
}

export function mainLoop() {
  if (state.dead) {
    if (interval) {
      clearInterval(interval);
    }
    die();
    return;
  }

  // see if we need to speed the game up
  if (state.speed_up) {
    state.speed_up = false;

    // reschedule main loop at different rate
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(mainLoop, 1000.0 / state.fps);
  }
  // normal flow
  else {
    update();
    draw();
  }
}

function update() {
  setState(state.dir);
}

/**
 * Set the state of the game.
 */
function setState(dir) {
  var head = state.queue[state.queue.length - 1];

  // check if hitting a wall
  // if not move the snake
  switch (dir) {
    case DIR_LEFT: {
      if (head.x > 0) {
        moveSnake(-1, 0);
      } else {
        markDead();
      }
      break;
    }
    case DIR_UP: {
      if (head.y > 0) {
        moveSnake(0, -1);
      } else {
        markDead();
      }
      break;
    }
    case DIR_RIGHT: {
      if (head.x < gridsize_x - 1) {
        moveSnake(1, 0);
      } else {
        markDead();
      }
      break;
    }
    case DIR_DOWN: {
      if (head.y < gridsize_y - 1) {
        moveSnake(0, 1);
      } else {
        markDead();
      }
      break;
    }
    default: {
      throw new Error("Unknown state encountered.");
    }
  }
}

function moveSnake(dx, dy) {
  var head = state.queue[state.queue.length - 1],
    newHead = {
      x: head.x + dx,
      y: head.y + dy,
    },
    ate = false;

  // check if hitting yourself
  for (const queueElement of state.queue) {
    if (queueElement.x === newHead.x && queueElement.y === newHead.y) {
      markDead();
      return;
    }
  }

  // check if eating fruit
  if (
    (newHead.x === state.food_coord.x && newHead.y === state.food_coord.y) ||
    (head.x === state.food_coord.x && head.y === state.food_coord.y)
  ) {
    state.food_coord.x = null;
    state.food_coord.y = null;
    state.eaten++;
    ate = true;
    dropRandomFruit();

    // possibly increase speed
    if (state.eaten === 3) {
      state.fps = 7.0;
      state.speed_up = true;
    } else if (state.eaten === 9) {
      state.fps = 9.0;
      state.speed_up = true;
    } else if (state.eaten === 17) {
      state.fps = 11.0;
      state.speed_up = true;
    } else if (state.eaten === 28) {
      state.fps = 13.0;
      state.speed_up = true;
    } else if (state.eaten === 39) {
      state.fps = 15.0;
      state.speed_up = true;
    } else if (state.eaten === 48) {
      state.fps = 17.0;
      state.speed_up = true;
    }
  }

  // if we eat the fruit then we don't move tail that round
  state.queue.push(newHead);
  if (!ate) {
    state.queue.shift();
  }
  state.last_dir = state.dir;
}

function markDead() {
  // set to dead, next time main loop runs it will stop itself
  state.dead = true;
}

function die() {
  // check top score
  var top_score = getCookie("ts"),
    message = "";

  if (top_score !== null) {
    message = "Your record score is " + top_score + ".";
  }

  if (top_score < state.eaten || top_score === null) {
    setCookie("ts", state.eaten, 60);
    message = "New record is " + state.eaten + ".";
  }

  if (window.confirm(message)) {
    onStart();
  }
}

/**
 * Draw the current state
 */
function draw() {
  clearCanvas(mainCanvas);

  drawGrid(newContext());

  //draw snake
  var c = newContext();
  for (var i = 0; i < state.queue.length; i++) {
    var o = state.queue[i];
    drawGridSquare(o.x, o.y, c, snakeStyle);
  }

  drawFruit(state.food_coord.x, state.food_coord.y);

  drawScore();
}

function dropRandomFruit() {
  // find where the snake is
  // and remove those spots from map of all spots
  var clone_foodmap = state.food_map.slice(0);
  state.queue.forEach(function (e) {
    var delLoc = e.x + "," + e.y,
      ind = clone_foodmap.indexOf(delLoc);

    if (ind !== -1) {
      clone_foodmap.splice(ind, 1);
    }
  });

  // then find random between the remaining spots
  var foodInd = Math.floor(Math.random() * clone_foodmap.length),
    emptyCoord = clone_foodmap[foodInd],
    ecarr = emptyCoord.split(","),
    ex = parseInt(ecarr[0]),
    ey = parseInt(ecarr[1]);

  state.food_coord = {
    x: ex,
    y: ey,
  };
}

function drawFruit(x, y) {
  var dc = mainCanvas.getContext("2d"),
    sx = x * squareSize,
    sy = y * squareSize,
    h_x = sx + squareSize / 2 - 1,
    h_y = sy + squareSize / 2 - 1;

  dc.fillStyle = fruitStyle;
  // west
  dc.fillRect(h_x - squareSize / 5, h_y, foodsize, foodsize);
  // east
  dc.fillRect(h_x + squareSize / 5, h_y, foodsize, foodsize);
  // north
  dc.fillRect(h_x, h_y + squareSize / 5, foodsize, foodsize);
  // south
  dc.fillRect(h_x, h_y - squareSize / 5, foodsize, foodsize);
}

/**
 * Draw score canvas in the top left corner
 */
function drawScore() {
  var tc = mainCanvas.getContext("2d");
  tc.fillStyle = "#4C4C4C";
  tc.font = "18px digital";
  tc.fillText(state.eaten, 5, 18);
}

/**
 * Draw a square on grid. If x or y bigger than board do nothing.
 * Both coordinates should be 0-indexed.
 * @param {Object} x position
 * @param {Object} y position
 * @param {Object} context drawing context
 * @param style fill style for squares
 */
function drawGridSquare(x, y, context, style) {
  if (x === null || x === undefined || y === null || y === undefined) {
    console.log("Coordinates shouldn't be null");
    return;
  }

  if (x < gridsize_x && y < gridsize_y) {
    context.fillStyle = style;
    context.fillRect(
      x * squareSize + 0.5,
      y * squareSize + 0.5,
      squareSize,
      squareSize
    );
  } else {
    console.log("(" + x + "," + y + ") too big for grid");
  }
}

/**
 * Draw the background
 */
function drawGrid(context) {
  // draw background
  context.save();
  context.fillStyle = backgroundStyle;
  context.fillRect(0.5, 0.5, width, height);
  context.restore();

  // draw borders
  context.moveTo(0.5, 0.5);
  context.lineTo(width + 0.5, 0.5);
  context.lineTo(width + 0.5, height + 0.5);
  context.lineTo(0.5, height + 0.5);
  context.lineTo(0.5, 0.5);
  context.strokeStyle = borderStyle;
  context.stroke();
  context.restore();
}

function newContext() {
  return mainCanvas.getContext("2d");
}

/**
 * Clear drawing on canvas. This is done by setting width to itself (a hack).
 */
function clearCanvas(canvas) {
  canvas.width = canvas.width;
}

/**
 * Handle keyboard inputs and filter invalid moves (back on itself);
 */
function onKeyDown(event) {
  // don't scroll the page when arrow keys are used
  

  var wantDir = event.keyCode - 37,
    ldir = state.last_dir;

  // the snake can't go back inside itself
  if (
    (wantDir === DIR_LEFT && ldir !== DIR_RIGHT) ||
    (wantDir === DIR_RIGHT && ldir !== DIR_LEFT) ||
    (wantDir === DIR_UP && ldir !== DIR_DOWN) ||
    (wantDir === DIR_DOWN && ldir !== DIR_UP)
  ) {
    event.preventDefault();
    state.dir = wantDir;
  }
  
}

/**
 * Save cookie
 * @param {Object} name
 * @param {Object} value
 * @param {Object} days expire in days days
 */
function setCookie(name, value, days) {
  let expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

/**
 * Retrieve cookie
 * @param {Object} name
 */
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
